import React from 'react'
import styled from 'styled-components'
import App from 'App'
import { COLOR_CONSTANTS, fontSizes } from 'theme'
import { CONTAINER_MAX_WIDTH, SCHEDULE_DEMO_LINK } from 'consts'
import { pxToRem } from 'helpers'
import SEO from 'components/SEO'
import { Flex, Grid } from 'components/Layout'
import Container from 'components/Container'
import { Text, H1, H2, H3 } from 'components/Typography'
import Footer from 'components/Footer'
import LocalImage from 'components/LocalImage'
import Button from 'components/Button'

import imageHeader from 'static/images/migrate/header_picture.webp'
import imageBeginJourney from 'static/images/migrate/begin_journey.webp'
import imageExpect from 'static/images/migrate/expect.webp'

import expectImageCall from 'static/images/migrate/expect/call.svg'
import expectImageTraining from 'static/images/migrate/expect/training.svg'
import expectImageRepresentative from 'static/images/migrate/expect/representative.svg'

const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  background: ${({ bg }) => bg || COLOR_CONSTANTS.WHITE};
`

const StyledGetStartedButton = styled(Button.Reversed)`
  font-size: ${fontSizes.m};
  height: 100%;
  padding-left: ${pxToRem(48)};
  padding-right: ${pxToRem(48)};
  box-shadow: 0px 18px 24px -8px rgba(216, 233, 255, 0.32);
`

const EXPECT_ITEMS = [
  {
    picture_url: expectImageCall,
    title: 'Onboarding Call',
    description: 'Connect with our team to discuss your current setup and business processes.',
  },
  {
    picture_url: expectImageTraining,
    title: 'Unlimited live Training',
    description:
      'Schedule training sessions for your team. Multiple sessions may be scheduled to go over all features and migration strategy.',
  },
  {
    picture_url: expectImageRepresentative,
    title: 'Dedicated account Representative',
    description: 'A team of dedicated account representatives will be assigned to your specific migration.',
  },
]

const Migrate = () => {
  return (
    <App fullHeader>
      <SEO
        title="Let us help you migrate to Vista Social"
        description="If you are currently using another social media management platform and need help in migrating to Vista Social, we are here to help! Our team has intimate knowledge of all top platforms that you might be using and will provide guidance in managing your migration."
      />

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex mb={{ mobile: 'xl', desktop: 'xxl' }} flexDirection="column" alignItems="center">
            <Grid
              mt="xl"
              gridTemplateColumns={{ mobile: '1fr', desktop: '1fr 1fr' }}
              gridGap="l"
              width="100%"
              alignItems="center"
              position="relative"
            >
              <Flex height="100%" width="100%" justifyContent="center" alignItems="center">
                <Flex flexDirection="column" maxWidth={{ mobile: '100%', desktop: '490px' }}>
                  <H1
                    fontSize="4xl"
                    fontWeight="bold"
                    color={COLOR_CONSTANTS.DENIM}
                    textAlign={{ mobile: 'center', desktop: 'left' }}
                  >
                    Let us{' '}
                    <H1
                      as="span"
                      fontSize="3xl"
                      fontWeight="bold"
                      color="primary"
                      textAlign={{ mobile: 'center', desktop: 'left' }}
                    >
                      help you migrate{' '}
                    </H1>
                    to Vista Social
                  </H1>
                  <Flex mt="m" pt="s">
                    <H2
                      color="secondaryText"
                      fontSize="l"
                      textAlign={{ mobile: 'center', desktop: 'left' }}
                      fontWeight="normal"
                    >
                      If you are currently using another social media management platform and need help in migrating to
                      Vista Social, we are here to help!
                      <br />
                      <br />
                      Our team has intimate knowledge of all top platforms that you might be using and will provide
                      guidance in managing your migration.
                    </H2>
                  </Flex>
                </Flex>
              </Flex>
              <Flex width="100%" ml="auto" mr={{ mobile: 'auto', desktop: 0 }}>
                <LocalImage
                  src={imageHeader}
                  width="100%"
                  mx="auto"
                  maxWidth="596px"
                  alt="Let us help you migrate to Vista Social"
                />
              </Flex>
            </Grid>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper bg="radial-gradient(51.88% 56.86% at 31.54% 45.31%, #0063E3 36.77%, #0250C9 100%)">
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex
            justifyContent={{ mobile: 'center', desktop: 'space-between' }}
            flexDirection={{ mobile: 'column', desktop: 'row' }}
            alignItems="center"
          >
            <Flex mt={{ mobile: 'l', desktop: '0' }} order={{ mobile: 2, desktop: 1 }} maxWidth="422px" width="100%">
              <LocalImage src={imageBeginJourney} width="100%" alt="Begin your journey today" />
            </Flex>
            <Flex
              order={{ mobile: 1, desktop: 2 }}
              flexDirection="column"
              alignItems={{ mobile: 'center', desktop: 'flex-start' }}
              width={{ mobile: '100%', desktop: '585px' }}
            >
              <Text fontWeight="bold" fontSize="4xl" color="white" textAlign={{ mobile: 'center', desktop: 'left' }}>
                Begin your journey{' '}
                <Flex
                  as="span"
                  fontWeight="bold"
                  fontSize="4xl"
                  color="white"
                  textAlign={{ mobile: 'center', desktop: 'left' }}
                  display={{ mobile: 'inline', desktop: 'block' }}
                >
                  today
                </Flex>
              </Text>

              <Flex mt="m" maxWidth={{ mobile: '100%', desktop: '465px' }}>
                <Text color={COLOR_CONSTANTS.SALT} fontSize="l" textAlign={{ mobile: 'center', desktop: 'left' }}>
                  Schedule a call with our team to discuss your business needs and requirements
                </Text>
              </Flex>

              <Flex mt="l" pt="m">
                <Flex height={{ mobile: pxToRem(56), tablet: pxToRem(52) }}>
                  <StyledGetStartedButton
                    as="a"
                    href={SCHEDULE_DEMO_LINK}
                    target="_blank"
                    rel="noreferrer noopener nofollow"
                  >
                    Schedule a Call
                  </StyledGetStartedButton>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex maxWidth="680px" flexDirection="column" mx="auto">
            <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
              What to{' '}
              <H1 as="span" fontSize="3xl" fontWeight="bold" color="primary">
                Expect
              </H1>
            </H3>
          </Flex>
          <Flex
            mt="l"
            pt="m"
            justifyContent={{ mobile: 'center', desktop: 'space-between' }}
            flexDirection={{ mobile: 'column', desktop: 'row' }}
            alignItems="center"
          >
            <Flex mt={{ mobile: 'l', desktop: '0' }} order={{ mobile: 2, desktop: 1 }} maxWidth="538px" width="100%">
              <LocalImage src={imageExpect} width="100%" alt="What to Expect" />
            </Flex>
            <Flex order={{ mobile: 1, desktop: 2 }}>
              <Grid gridGap="l" gridTemplateColumns="repeat(1, 1fr)">
                {EXPECT_ITEMS.map(({ picture_url, title, description }) => (
                  <Flex flexDirection="column" key={title} width="100%" maxWidth={{ mobile: '100%', desktop: '430px' }}>
                    <Flex alignItems="center">
                      <LocalImage src={picture_url} width="44px" height="44px" alt={title} />
                      <Text ml="m" color={COLOR_CONSTANTS.DENIM} fontWeight="bold" fontSize="l">
                        {title}
                      </Text>
                    </Flex>
                    <Text mt="m" fontSize="m" color="secondaryText" textAlign="left">
                      {description}
                    </Text>
                  </Flex>
                ))}
              </Grid>
            </Flex>
          </Flex>
        </Container>
      </Wrapper>

      <Footer />
    </App>
  )
}

export default Migrate
